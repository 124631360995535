import React from 'react';
import QANavbar from './QANavbar';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const data = [
  {
    course_Name: "Physics",
    instructor_Name: "John Doe",
    created_date: "23-12-2023",
    student: "John Doe",
    report: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Quibusdam sapiente quos deserunt minima at sed libero reprehenderitaliquid similique magnam reiciendis dolorum eius, sit natus corporisexpedita impedit accusantium sint?",
  },
  {
    course_Name: "Physics",
    instructor_Name: "John Doe",
    created_date: "23-12-2023",
    student: "John Doe",
    report: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Quibusdam sapiente quos deserunt minima at sed libero reprehenderitaliquid similique magnam reiciendis dolorum eius, sit natus corporisexpedita impedit accusantium sint?",
  },
];

function QAShowReports() {
  return (
    <div>
         <QANavbar/>

      <div className="sticky_bar">
        <p>Manage Reports</p>
      </div>
      <TableContainer component={Paper} sx={{ width: "80%", margin: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Course Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Instructor Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Created Date
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Student
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Report
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {" "}
            {data.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{
                  borderBottom:
                    index === data.length - 1 ? "none" : "1px solid #ddd",
                }}
              >
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.course_Name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.instructor_Name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.created_date}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.student}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.report}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default QAShowReports;
