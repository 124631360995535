import React from 'react';
import StudentNav from './StudentNav';

function StudentExamPage() {
  return (
    <div>
         <StudentNav/>
      <div className="sticky_bar">
        <p>Course: Exam</p>
      </div>
      <div className="form_container">
        <p className="form_title">DX231212</p>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">How can you avoid overfitting your model?</label>
            <textarea placeholder="Type your answer here..." cols="35" rows="6"></textarea>
          </div>
          <div className="form_field">
            <label htmlFor="">Explain the steps in making a decision tree.</label>
            <textarea placeholder="Type your answer here..." cols="35" rows="6"></textarea>
          </div>
          <div className="form_field">
            <label htmlFor="">What are the differences between supervised and unsupervised learning?</label>
            <textarea placeholder="Type your answer here..." cols="35" rows="6"></textarea>
          </div>
          <div className="form_field">
            <label htmlFor="">Full form of PAC is _________________</label>
            <textarea placeholder="Type your answer here..." cols="35" rows="1"></textarea>&nbsp;
          </div>
          <div className="form_field">
            <label htmlFor="">(Or) Add your answer sheet as a file here.</label>
            <input type="file" id="file_attachment" name="file_attachment" />
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default StudentExamPage;
