import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorManageCourse() {
  return (
    <div className="instructor-create-course">
        <InstructorNav/>
      <div className="other_than_student">
        <p className="page_name_qa">Welcome back, <span>Instructor!</span></p>
      </div>
      <br/>
      <div className="sticky_bar">
        <p>Create Course</p>
      </div>

      <div className="form_container">
        <p className="form_title">Create a Course</p>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="courseName">Course Name</label>
            <input type="text" id="courseName" />
          </div>
          <div className="form_field">
            <label htmlFor="courseID">Course ID</label>
            <input type="text" id="courseID" />
          </div>
          <div className="form_field">
            <label htmlFor="createdDate">Created Date</label>
            <input type="text" id="createdDate" />
          </div>
          <div className="form_field">
            <label htmlFor="description">Description</label>
            <textarea rows="6" id="description"></textarea>
          </div>
          <div className="form_field">
            <label htmlFor="activeStudents">Active Students</label>
            <input type="text" id="activeStudents" />
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Create</button>
        </div>
      </div>
    </div>
  );
}

export default InstructorManageCourse;
