import React from 'react';
import AdminNav from './AdminNav';
import { Link } from 'react-router-dom';

function AdminHomePage() {
  return (
    <div>
      
       <AdminNav/>
      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Admin!</span>
        </p>
      </div>

      <div className="admin_home_container">
        <div className="admin_item">
          <p>Student</p>
          <Link to='/adminManageStudents'>Manage student</Link>
          <Link to = '/adminManageGrades'>Manage Grade</Link>
          <Link to = '/adminTalkToUsers'>Talk To students</Link>
        </div>

        <div className="admin_item">
          <p>Instructor</p>
          <Link to = '/adminManageInstructors'>Manage instructor</Link>
          <Link to = '/adminManageCourse'>Manage course</Link>
          <Link to = '/adminManageExam'>Manage Exam</Link>
        </div>

        <div className="admin_item">
          <p>Coordinator</p>
          <Link to= '/adminManageCoordinator'>Manage coordinator</Link>
          <Link to= '/adminManagePrograms'>Manage program</Link>
          <Link to= ''>Reviews</Link>
        </div>

        <div className="admin_item">
          <p>QA-officer</p>
          <Link to= '/adminManageQA'>Manage QA-officer</Link>
          <Link to= ''>Assign course</Link>
          <Link to= ''>Reports</Link>
        </div>
      </div>

     

    </div>
  );
}

export default AdminHomePage;
