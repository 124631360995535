import React from 'react';
import QANavbar from './QANavbar';

function QAAccount() {
  const editField = (field) => {
    // Implement your editField function logic here
    // You can use state to toggle edit mode and update data
    console.log(`Editing ${field}`);
  };

  return (
    <div>
 
    <QANavbar/>
      <form>
        <div className="form_container">
          <p className="form_title">QA Officer Details</p>
          <div className="form_group">
            <div className="form_field">
              <label htmlFor="">Username</label>
              <span id="currentUsername">Current Username </span>
              <a href="#" onClick={() => editField('username')}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label htmlFor="">ID</label>
              <span id="currentUtaId">Current UTA ID </span>
              <a href="#" onClick={() => editField('utaId')}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label htmlFor="">Email</label>
              <span id="currentEmail">Current Email </span>
              <a href="#" onClick={() => editField('email')}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label htmlFor="password">Password</label>
              <span id="currentPassword">Current Password </span>
              <a href="changepass.html" onClick={() => editField('password')}>
                <u>edit</u>
              </a>
              <button type="button" id="togglePassword" className="toggle-password">
                <i aria-hidden="true" id="togglePasswordIcon"></i>
              </button>
            </div>
            <div className="form_footer">
              <button type="submit" className="form_submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

    
    </div>
  );
}

export default QAAccount;
