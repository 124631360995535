import React from "react";

function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <a
            className="footer_link"
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-instagram"></i>
          </a>
        </li>
        <li>
          <a
            className="footer_link"
            href="https://www.facebook.com/login.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook"></i>
          </a>
        </li>
        <li>
          <a
            className="footer_link"
            href="https://twitter.com/?lang=en-in"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-twitter"></i>
          </a>
        </li>
        <li>
          <a
            className="footer_link"
            href="https://www.linkedin.com/feed/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-linkedin"></i>
          </a>
        </li>
      </ul>
      <h1>
        &copy; 2023 MSDS, UTA. All rights reserved.
        <a href="/" target="_blank"></a>
      </h1>
    </footer>
  );
}

export default Footer;
