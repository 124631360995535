import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

export default function MainNav({ setOpen }) {
  const handleOpenMenu = () => {
    setOpen((prev) => !prev);
  };
  return (
    <nav>
      <Link to="/">
        <img src="Logo.png" className="logo" alt="logo" srcset="" />
      </Link>
      <ul>
        <li>
          <Link
            to="/"
            className="nav_item active"
            style={{ textDecoration: "none", color: "white" }}
          >
            Home
          </Link>
        </li>
        <li>
          <a
            href="https://pxi3111.uta.cloud/"
            target="_blank"
            className="nav_item active"
            style={{ textDecoration: "none", color: "white" }}
          >
            Blog
          </a>
        </li>
        <li>
          <Link to="/student" className="nav_item">
            Student
          </Link>
        </li>
        <li>
          <Link to="/instructor" className="nav_item">
            Instructor
          </Link>
        </li>
        <li>
          <Link to="/coordinator" className="nav_item">
            Coordinator
          </Link>
        </li>
        <li>
          <Link to="/QA" className="nav_item">
            QA Officer
          </Link>
        </li>

        <li>
          <Link to="/admin" className="nav_item">
            Admin
          </Link>
        </li>
        <li>
          <Link to="/login" className="nav_item">
            Login/register
          </Link>
        </li>
        <li>
          <Link to="/about" className="nav_item">
            About program
          </Link>
        </li>
        <li>
          <Link to="/services" className="nav_item">
            Services
          </Link>
        </li>
        <li>
          <Link to="/contactUs" className="nav_item">
            Contact US
          </Link>
        </li>
      </ul>
      <div className="menuIcon" onClick={handleOpenMenu}>
        <MenuIcon />
      </div>
    </nav>
  );
}
