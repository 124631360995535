import React from 'react';
import QANavbar from './QANavbar';

function QAManageCourse() {
  return (
    <div>
 <QANavbar/>

      <div className="sticky_bar">
        <p>Manage Course</p>
      </div>

      <table id="table">
        <tr>
          <th>Course Name</th>
          <th>Instructor Name</th>
          <th>Created Date</th>
          <th>Branch</th>
        </tr>
        <tr>
          <td>Physics</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>BSC computer science</td>
        </tr>
        <tr>
          <td>Physics</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>BSC computer science</td>
        </tr>
      </table>
    </div>
  );
}

export default QAManageCourse;
