import React from 'react';
import AdminNav from './AdminNav';

function AdminManageUsers() {
  return (
    <div>
             <AdminNav/>
      <div className="sticky_bar">
        <p>Manage Instructors</p>
      </div>

      <table id="table">
        <tr>
          <th>Instructor Name</th>
          <th>Instructor ID</th>
          <th>Email id</th>
          <th>Courses teach</th>
          <th>Class Analytics: Pass %</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>jxm785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA 3402, DATA 4572</u>
            </a>
          </td>
          <td>80%, 75%</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Linda Christian</td>
          <td>1075375785</td>
          <td>lxc785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA 4572</u>
            </a>
          </td>
          <td>95%</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>

      <div className="sticky_bar">
        <p>Manage QAs</p>
      </div>
      <table id="table">
        <tr>
          <th>QA Name</th>
          <th>QA ID</th>
          <th>Email id</th>
          <th>Contributed Programs</th>
          <th>Result</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>jxm785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA SCIENCE</u>
            </a>
          </td>
          <td>Placements increased by 30%</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Linda Christian</td>
          <td>1075375785</td>
          <td>lxc785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA SCIENCE</u>
            </a>
          </td>
          <td>Student satisfaction with the program is increased.</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>

      <div className="sticky_bar">
        <p>Manage Coordinators</p>
      </div>
      <table id="table">
        <tr>
          <th>Coordinator Name</th>
          <th>Coordinator ID</th>
          <th>Email id</th>
          <th>Courses Coordinated</th>
          <th>Improvements made</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>jxm785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA 3402, DATA 4572</u>
            </a>
          </td>
          <td>abc</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Linda Christian</td>
          <td>1075375785</td>
          <td>lxc785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA 4572</u>
            </a>
          </td>
          <td>xyz</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>

      <div className="sticky_bar">
        <p>Manage Students</p>
      </div>

      <table id="table">
        <tr>
          <th>Student name</th>
          <th>Student ID</th>
          <th>Course ID</th>
          <th>Report</th>
          <th>Grade</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>DATA 3402, DATA 4572</td>
          <td>
            <a href="#" target="_blank">
              <u>click here</u>
            </a>
          </td>
          <td>B</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Agatha Princely</td>
          <td>1054325785</td>
          <td>DATA 3402, DATA 3892</td>
          <td>
            <a href="#" target="_blank">
              <u>click here</u>
            </a>
          </td>
          <td>A</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>
      <br /><br /><br />
    </div>
  );
}

export default AdminManageUsers;
