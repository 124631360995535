import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentAccountPage from "./StudentAccount";
import StudentAnalyticsPage from "./StudentAnalytic";
import StudentChangePasswordPage from "./StudentChangePass";
import StudentCourseContentPage from "./StudentContent";
import StudentDiscussionPage from "./StudentDiscussion";
import StudentExamPage from "./StudentExam";
import StudentFeedback from "./StudentFeedback";
import StudentGrades from "./StudentGrades";
import StudentHome from "./StudentHome";
import StudentNav from "./StudentNav";
import Footer from "../Navbar_and_Footer/Footer";

export default function RoutingStudent() {
  return (
    <div style={{ width: "100%" }}>
      <Routes>
        <Route path="/student" element={<StudentHome />} />
        <Route path="/studentAccount" element={<StudentAccountPage />} />
        <Route path="/studentAnalytic" element={<StudentAnalyticsPage />} />
        <Route
          path="/studentchangePassword"
          element={<StudentChangePasswordPage />}
        />
        <Route path="/studentContent" element={<StudentCourseContentPage />} />
        <Route path="/studentdiscussion" element={<StudentDiscussionPage />} />
        <Route path="/studentExam" element={<StudentExamPage />} />
        <Route path="/studentFeedback" element={<StudentFeedback />} />
        <Route path="/studentGrades" element={<StudentGrades />} />
      </Routes>
    </div>
  );
}
