import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainNav from "./MainNav";
import Home_Image from "../../assets/home_image.png";

function MainPage() {
  const [open, setOpen] = useState(false);

  return (
    <div className="main-page">
      <MainNav setOpen={setOpen} />
      {open && (
        <div className="sidebar">
          <div>
            <Link
              to="/"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>
          </div>
          <div>
            <a
              href="https://pxi3111.uta.cloud/"
              target="_blank"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Blog
            </a>
          </div>
          <div>
            <Link to="/student" className="nav_item">
              Student
            </Link>
          </div>
          <div>
            <Link to="/instructor" className="nav_item">
              Instructor
            </Link>
          </div>
          <div>
            <Link to="/coordinator" className="nav_item">
              Coordinator
            </Link>
          </div>
          <div>
            <Link to="/QA" className="nav_item">
              QA Officer
            </Link>
          </div>

          <div>
            <Link to="/admin" className="nav_item">
              Admin
            </Link>
          </div>
          <div>
            <Link to="/login" className="nav_item">
              Login/register
            </Link>
          </div>
          <div>
            <Link to="/about" className="nav_item">
              About program
            </Link>
          </div>
          <div>
            <Link to="/services" className="nav_item">
              Services
            </Link>
          </div>
          <div>
            <Link to="/contactUs" className="nav_item">
              Contact US
            </Link>
          </div>
        </div>
      )}
      <div className="home_container">
        <div className="content_side">
          <h4>MS in Data Science</h4>
          <p>
            Welcome to our Masters in Data Science program! We are dedicated to
            providing students with a cutting-edge education that blends theory
            and hands-on experience. Our curriculum is designed to equip you
            with the latest data science techniques, tools, and
            industry-relevant skills. Join us to embark on a transformative
            journey into the world of data science and unlock endless
            opportunities for innovation and impact.
            <br />
            <br />
            The Data Science program helps meet the growing need for
            application-oriented engineers who can use data science tools and
            techniques to solve complex problems in industries such as
            manufacturing, logistics, healthcare, and energy.
            <br />
            <br />
            The program is unique for its breadth, offering a widely
            encompassing set of courses that allows students with or without a
            programming background to develop the necessary data science skills.
            It aims to instill the acumen needed to draw insights from data, to
            make sound decisions using data, and to effectively communicate
            about data-driven findings and decisions.
          </p>
          <div className="social_medias">
            <h4>@MSDSatUTA</h4>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/login.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/?lang=en-in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/feed/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <img src={Home_Image} alt="" className="content_image" />
        </div>
      </div>
    </div>
  );
}

export default MainPage;
