import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminHomePage from './AdminHome'
import AdminAccountPage from './AdminAccount'
import AdminChangePassword from './Adminchangepassword'
import AdminManageQA from './AdminManageQA'
import AdminManageCoordinator from './AdminMannageCoordinate'
import AdminManageExam from './AdminMannageExam'
import AdminManageCourse from './AdminMannageCourse'
import AdminManageGrades from './AdminMannageGrade'
import AdminManageInstructors from './AdminMannageInstructure'
import AdminManagePrograms from './AdminMannageProgramme'
import AdminManageStudents from './AdminMannageStudent'
import AdminManageUsers from './AdminMannageUsers'
import AdminGrades from './AdminGrades'
import AdminTalkToUsers from './AdminTalktoUsers'

export default function AdminRouting() {
  return (
    <div>
         <Routes>
     
            <Route path = '/admin' element = {<AdminHomePage/>}/>
            <Route path = '/adminAccount' element = {<AdminAccountPage/>}/>
            <Route path = '/adminchangePassword' element = {<AdminChangePassword/>}/>
            <Route path = '/adminManageQA' element = {<AdminManageQA/>}/>
            <Route path = '/adminManageCoordinator' element = {<AdminManageCoordinator/>}/>
            <Route path = '/adminManageExam' element = {<AdminManageExam/>}/>
            <Route path = '/adminManageCourse' element = {<AdminManageCourse/>}/>
            <Route path = '/adminManageGrades' element = {<AdminManageGrades/>}/>
            <Route path = '/adminManageInstructors' element = {<AdminManageInstructors/>}/>
            <Route path = '/adminManagePrograms' element = {<AdminManagePrograms/>}/>
            <Route path = '/adminManageStudents' element = {<AdminManageStudents/>}/>
            <Route path = '/adminManageUsers' element = {<AdminManageUsers/>}/>
            <Route path = '/adminGrades' element = {<AdminGrades/>}/>
            <Route path = '/adminTalkToUsers' element = {<AdminTalkToUsers/>}/>
       
        </Routes>

    </div>
  )
}
