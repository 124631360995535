import React, { useState } from "react";
import MainNav from "./MainNav";
import { Link } from "react-router-dom";

function RegisterPage() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [open, setOpen] = useState(false);
  return (
    <div>
      <MainNav setOpen={setOpen} />
      {open && (
        <div className="sidebar">
          <div>
            <Link
              to="/"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>
          </div>
          <div>
            <a
              href="https://pxi3111.uta.cloud/"
              target="_blank"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Blog
            </a>
          </div>
          <div>
            <Link to="/student" className="nav_item">
              Student
            </Link>
          </div>
          <div>
            <Link to="/instructor" className="nav_item">
              Instructor
            </Link>
          </div>
          <div>
            <Link to="/coordinator" className="nav_item">
              Coordinator
            </Link>
          </div>
          <div>
            <Link to="/QA" className="nav_item">
              QA Officer
            </Link>
          </div>

          <div>
            <Link to="/admin" className="nav_item">
              Admin
            </Link>
          </div>
          <div>
            <Link to="/login" className="nav_item">
              Login/register
            </Link>
          </div>
          <div>
            <Link to="/about" className="nav_item">
              About program
            </Link>
          </div>
          <div>
            <Link to="/services" className="nav_item">
              Services
            </Link>
          </div>
          <div>
            <Link to="/contactUs" className="nav_item">
              Contact US
            </Link>
          </div>
        </div>
      )}
      <form>
        <div className="form_container">
          <p className="form_title">Register Here!</p>
          <div className="form_group">
            <div className="form_field">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                placeholder="Username"
                required
              />
            </div>
            <div className="form_field">
              <label htmlFor="id">ID</label>
              <input
                type="number"
                id="id"
                minLength="10"
                maxLength="10"
                placeholder="UTA ID"
                required
              />
            </div>
            <div className="form_field">
              <label htmlFor="email">Email</label>
              <input type="text" id="email" placeholder="Email" required />
            </div>
            <div className="form_field">
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                required
              />
              <button
                type="button"
                id="togglePassword"
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                <i
                  aria-hidden="true"
                  id="togglePasswordIcon"
                  className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                ></i>
              </button>
            </div>
          </div>
          <div className="form_footer">
            <button type="submit" className="form_submit">
              Submit
            </button>
            <span className="forgot_span2">
              Already have an account?
              <Link to="/login" className="form_link">
                Login here
              </Link>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
}

export default RegisterPage;
