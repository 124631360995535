import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorGrades() {
  return (
    <div className="instructor-grades">
        <InstructorNav/>
      <div className="sticky_bar">
        <p>Manage Grade</p>
      </div>

      <table id="table">
        <thead>
          <tr>
            <th>Student name</th>
            <th>Course</th>
            <th>Student Email</th>
            <th>Grade</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>User 1</td>
            <td>Course 1</td>
            <td>abac@gamil.com</td>
            <td>A+</td>
          </tr>
          <tr>
            <td>User 2</td>
            <td>Course 2</td>
            <td>xyz@gmail.com</td>
            <td>A</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default InstructorGrades;
