import React from "react";
import CoordinatorNav from "./CoordinatorNav";

function CoordinatorCreateProgram() {
  return (
    <div>
      <CoordinatorNav />
      <div className="form_container">
        <p className="form_title">Create Program</p>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">Program Name</label>
            <input type="text" />
          </div>
          <div className="form_field">
            <label htmlFor="">Course list</label>
            <input type="text" />
          </div>
          <div className="form_field">
            <label htmlFor="">Program Objective and Description</label>
            <textarea rows="5" cols="30"></textarea>
          </div>
          <div className="form_field">
            <label htmlFor="program_info">Program file</label>
            <input type="file" id="file_attachment" name="file_attachment" />
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default CoordinatorCreateProgram;
