import React from 'react';
import AdminNav from './AdminNav';

function AdminManagePrograms() {
  return (
    <div>
   
   <AdminNav/>
      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Admin!</span>
        </p>
      </div> <br/>

      <div className="sticky_bar">
        <p>Manage Program</p>
      </div>

      <table id="table">
        <tr>
          <th>Name</th>
          <th>Creator name</th>
          <th>Date of program</th>
          <th>Subject</th>
          <th>Branch</th>
        </tr>
        <tr>
          <td>ABC program</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>Hacking</td>
          <td>BSC computer science</td>
        </tr>
        <tr>
          <td>ABC program</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>Hacking</td>
          <td>BSC computer science</td>
        </tr>
        <tr>
          <td>ABC program</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>Hacking</td>
          <td>BSC computer science</td>
        </tr>
      </table>

    
    </div>
  );
}

export default AdminManagePrograms;
