import React from 'react';
import { Link } from 'react-router-dom';
import CoordinatorNav from './CoordinatorNav';

function CoordinatorAccount() {
  function editField(a){
    
  }
  return (
    <div>
   
       <CoordinatorNav/>
      <form>
        <div className="form_container">
          <p className="form_title">Coordinator Details</p>
          <div className="form_group">
            <div className="form_field">
              <label for="">Username</label>
              <span id="currentUsername">Current Username</span>
              <a href="" onClick={() => editField('username')}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label for="">ID</label>
              <span id="currentUtaId">Current UTA ID</span>
              <a href="" onClick={() => editField('utaId')}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label for="">Email</label>
              <span id="currentEmail">Current Email</span>
              <a href="" onClick={() => editField('email')}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label for="password">Password</label>
              <span id="currentPassword">Current Password</span>
              <Link to = '/coordinatorchangePassword' onClick={() => editField('password')}>
                <u>edit</u>
              </Link>
              <button type="button" id="togglePassword" className="toggle-password">
                <i aria-hidden="true" id="togglePasswordIcon"></i>
              </button>
            </div>
          </div>
          <div className="form_footer">
            <button type="submit" className="form_submit">
              Submit
            </button>
          </div>
        </div>
      </form>
  
 
  </div>
  );
}

export default CoordinatorAccount;
