import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorCreateCourse() {
  return (
    <div className="instructor-create-course">
        <InstructorNav/>
      <div className="form_container">
        <h2 className="form_title">Create Course</h2>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">Course Code</label>
            <input type="text" />
          </div>
          <div className="form_field">
            <label htmlFor="">Course Name</label>
            <input type="text" />
          </div>
          <div className="form_field">
            <label htmlFor="">Course Content</label>
            <textarea rows="5" cols="30"></textarea>
            <input type="file" id="file_attachment" name="file_attachment" />
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default InstructorCreateCourse;
