import React from 'react'
import QAAccount from './QA_account'
import QAManageCourse from './QAMannageCourse'
import QAManageProgram from './QAMannageProgramme'
import QAShowReports from './QAshowreports'
import QATalkToUsers from './QATalkUsers'
import QAHome from './QA_Home'
import { Route, Routes } from 'react-router-dom'
import QaHome from './QA_Home'

export default function QARouting() {
  return (
    <div>
           <Routes>
                <Route path = '/QA' element = {<QaHome/>}/>
                <Route path = '/QAAccount' element = {<QAAccount/>}/>
                <Route path = '/QAManageCourse' element = {<QAManageCourse/>}/>
                <Route path = '/QAManagePrograme' element = {<QAManageProgram/>}/>
                <Route path = '/QAShowReport' element = {<QAShowReports/>}/>
                <Route path = '/QATalkToUsers' element = {<QATalkToUsers/>}/>
          </Routes>
    </div>
  )
}




