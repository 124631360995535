import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorStudentFeedbacks() {
  return (
    <div className="student-feedbacks">
     <InstructorNav/>

      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Instructor!</span>
        </p>
      </div>
      <br/>
      <div className="sticky_bar">
        <p>Student Feedbacks</p>
      </div>

      <table id="table">
        <tr>
          <th>Student name</th>
          <th>Course</th>
          <th>Student Email</th>
          <th>Feedback</th>
        </tr>
        <tr>
          <td>User 1</td>
          <td>Course 1</td>
          <td>abac@gamil.com</td>
          <td>sdsdsadas</td>
        </tr>
        <tr>
          <td>User 1</td>
          <td>Course 1</td>
          <td>abac@gamil.com</td>
          <td>sadasfsaf</td>
        </tr>
      </table>

    </div>
  );
}

export default InstructorStudentFeedbacks;
