import React from 'react';
import AdminNav from './AdminNav';

function AdminAccountPage() {
  // Define your state variables and event handlers here
  // For example, if you want to handle the editField function:
  const editField = (fieldName) => {
    // Implement your logic here
  };

  return (
    <div>
      <AdminNav/>
      <form>
        <div className="form_container">
          <p className="form_title">Admin Details</p>
          <div className="form_group">
            {/* Replace the spans and links with state variables and event handlers */}
            <div className="form_field">
              <label htmlFor="">Username</label>
              <span id="currentUsername">Current Username </span>
              <a href="#" onClick={() => editField('username')}>
                <u>edit</u>
              </a>
            </div>
            {/* Repeat this structure for other form fields */}
          </div>
          <div className="form_field">
            <label htmlFor="password">Password</label>
            <span id="currentPassword">Current Password </span>
            <a href="changepass.html" onClick={() => editField('password')}>
              <u>edit</u>
            </a>
            <button type="button" id="togglePassword" className="toggle-password">
              <i aria-hidden="true" id="togglePasswordIcon"></i>
            </button>
          </div>
          <div className="form_footer">
            <button type="submit" className="form_submit">
              Submit
            </button>
          </div>
        </div>
      </form>

     
    
    </div>
  );
}

export default AdminAccountPage;
