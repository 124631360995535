import React from 'react'
import CoordinatorNav from './CoordinatorNav'

export default function CoordinatorChangePassword() {
  return (
    <div>
            <CoordinatorNav/>
       

       <div className="form_container">
         <p className="form_title">Change password</p>
         <div className="form_group">
           <div className="form_field">
             <label htmlFor="">Email</label>
             <input type="text" placeholder="email" />
           </div>
           <div className="form_field">
             <label htmlFor="">Current Password</label>
             <input type="password" placeholder="current password" />
           </div>
           <div className="form_field">
             <label htmlFor="">New Password</label>
             <input type="password" placeholder="new password" />
           </div>
         </div>
         <div className="form_footer">
           <button className="form_submit">Submit</button>
         </div>
       </div>
    </div>
  )
}
