import logo from "./logo.svg";
import "./App.css";
import ChangePassword from "./Component/Admin/Adminchangepassword";

import Footer from "./Component/Navbar_and_Footer/Footer";
import AboutPage from "./Component/Main/About";
import ContactPage from "./Component/Main/contact";
import LoginPage from "./Component/Main/Login";
import ServicesPage from "./Component/Main/Service";
import RoutingStudent from "./Component/Student/RoutingStudent";
import MainPage from "./Component/Main/Main";
import { Route, Routes } from "react-router-dom";
import Chatbox from "./Component/Chatbox";
import RoutingInstructor from "./Component/Instructure/InstructorRouting";
import CoordinatorRouting from "./Component/Coordinator/CoordinatorRouting";
import QARouting from "./Component/QA/QARouting";
import AdminRouting from "./Component/Admin/AdminRouting";
import RegisterPage from "./Component/Main/Register";
import ForgotPassword from "./Component/Main/ForgetPassword";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/contactUs" element={<ContactPage />} />
      </Routes>
      <CoordinatorRouting />
      <RoutingStudent />
      <RoutingInstructor />
      <QARouting />
      <AdminRouting />
      <Footer />
      <Chatbox />
    </div>
  );
}

export default App;
