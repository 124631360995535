import React, { useState } from "react";
import MainNav from "./MainNav";
import { Link } from "react-router-dom";

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <MainNav setOpen={setOpen} />
      {open && (
        <div className="sidebar">
          <div>
            <Link
              to="/"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>
          </div>
          <div>
            <a
              href="https://pxi3111.uta.cloud/"
              target="_blank"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Blog
            </a>
          </div>
          <div>
            <Link to="/student" className="nav_item">
              Student
            </Link>
          </div>
          <div>
            <Link to="/instructor" className="nav_item">
              Instructor
            </Link>
          </div>
          <div>
            <Link to="/coordinator" className="nav_item">
              Coordinator
            </Link>
          </div>
          <div>
            <Link to="/QA" className="nav_item">
              QA Officer
            </Link>
          </div>

          <div>
            <Link to="/admin" className="nav_item">
              Admin
            </Link>
          </div>
          <div>
            <Link to="/login" className="nav_item">
              Login/register
            </Link>
          </div>
          <div>
            <Link to="/about" className="nav_item">
              About program
            </Link>
          </div>
          <div>
            <Link to="/services" className="nav_item">
              Services
            </Link>
          </div>
          <div>
            <Link to="/contactUs" className="nav_item">
              Contact US
            </Link>
          </div>
        </div>
      )}
      <div className="form_container">
        <p className="form_title">Login Here!</p>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" placeholder="Username" required />
          </div>
          <div className="form_field">
            <label htmlFor="password">Password</label>
            <div className="passwordInputWrapper">
              <input
                className="passwordInput"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
              />
              <div onClick={togglePasswordVisibility}>
                <i
                  aria-hidden="true"
                  id="togglePasswordIcon"
                  className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                ></i>
              </div>
            </div>
            <button
              type="button"
              id="togglePassword"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            ></button>
          </div>
          <Link to="/forgotPassword" className="form_link forgot_pass">
            Forgot Password?
          </Link>
        </div>
        <div className="form_footer">
          <button className="form_submit" type="submit">
            Submit
          </button>
          <span className="forgot_span">
            Don't have an account?{" "}
            <Link to="/register" className="form_link">
              Register here
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
