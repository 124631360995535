import React from "react";
import InstructorNav from "./InstructorNav";
import { Link } from "react-router-dom";

function InstructorHome() {
  return (
    <div>
      <InstructorNav />
      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Instructor!</span>
        </p>
      </div>

      <div className="instructor_options">
        <Link to="/instructorCreateCourse">Create Course</Link>
        <Link to="/instructorCreateExam"> Create an exam</Link>
        <Link to="">Create a Report</Link>
        <Link to="/instructorGrade">Grade students</Link>
        <Link to="/intructorManageCourse">Manage Course</Link>
        <Link to="/instructorStudentFeedback">Student feedbacks</Link>
      </div>

      <div className="instructor_home_container">
        <div className="exam_analysis">
          <h2>Exam Analysis</h2>
          <div className="analysis_sec">
            <p>Exam 1</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 2</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 3</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 4</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 5</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 6</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 7</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div className="analysis_sec">
            <p>Exam 8</p>
            <div className="exam_icons">
              <i className="fa fa-upload"></i>
              <i className="fa fa-pencil"></i>
              <i className="fa fa-trash"></i>
            </div>
          </div>
        </div>
        <div className="exam_analysis">
          <h2>Student report</h2>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727192</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727197</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727194</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727192</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727194</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727191</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727198</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
          <div className="analysis_sec">
            <i className="fa fa-user-o"></i>
            <p>104727190</p>
            <i className="fa fa-file-pdf-o"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorHome;
