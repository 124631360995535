import React from 'react';
import QANavbar from './QANavbar';
import { Link } from 'react-router-dom';

function QaHome() {
  return (
    <div>
      <QANavbar/>
          

      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>QA-officer!</span>
        </p>
      </div>
      <div className="qa_home_container">
        <Link to = '/QAManagePrograme' className="qa_item">
          Manage/Review Programs
        </Link>
        <Link to = '/QAManageCourse' className="qa_item">
          Manage/Review Course
        </Link>
        <Link to = '/QATalkToUsers' className="qa_item">
          Talk to Admins
        </Link>
        <Link to = '/QAShowReport' className="qa_item">
          Monitor Students reports
        </Link>
        <Link to = '/QATalkToUsers' className="qa_item">
          Talk to Instructor
        </Link>
        <Link to = '/QATalkToUsers' className="qa_item">
          Talk to Program Coordinator
        </Link>
      </div>
    
   
    </div>
  );
}

export default QaHome;
