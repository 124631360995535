import React from 'react'
import { Route, Routes } from 'react-router-dom'
import InstructorAccount from './InstrucutureAccount'
import InstructorChangePassword from './InstructorChangePass'
import InstructorCreateCourse from './InstructorCreateCourse'
import InstructorFiles from './InstructorFile'
import InstructorManageCourse from './InstructorManageCourse'
import InstructorHome from './InstructorHome'
import InstructorCreateExam from './InstructorCreateExam'
import InstructorStudentDetails from './InstructorStudentDetails'
import InstructorStudentFeedbacks from './InstructorStudentFeedback'
import InstructorGrades from './InstrutorGrades'


export default function RoutingInstructor() {
  return (
    <div>
      
        <Routes>
     
            <Route path = '/instructor' element = {<InstructorHome/>}/>
            <Route path = '/instructorchangePassword' element = {<InstructorChangePassword/>}/>
            <Route path = '/instructorCreateCourse' element = {<InstructorCreateCourse/>}/>
            <Route path = '/instructorCreateExam' element = {<InstructorCreateExam/>}/>
            <Route path = '/instructorFile' element = {<InstructorFiles/>}/>
            <Route path = '/intructorManageCourse' element = {<InstructorManageCourse/>}/>
            <Route path = '/instructorStudentDetails' element = {<InstructorStudentDetails/>}/>
            <Route path = '/instructorStudentFeedback' element = {<InstructorStudentFeedbacks/>}/>
            <Route path = '/instructorAccount' element = {<InstructorAccount/>}/>
            <Route path = '/instructorGrade' element = {<InstructorGrades/>}/>

        </Routes>
   
    </div>
  )
}
