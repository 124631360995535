import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import ActionFlag from "../Redux/Action";

export default function CoordinatorNav() {
  const [open, setOpen] = useState(false);
  const handleOpenMenu = () => {
    setOpen(!open);
  };
  return (
    <div>
      <header>
        <nav>
          <Link to="/">
            <img src="Logo.png" className="logo" alt="" srcSet="" />
          </Link>
          <ul>
            <li>
              <Link to="/coordinator" className="nav_item">
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/coodinatorAccount"
                className="nav_item active"
                style={{ textDecoration: "none", color: "white" }}
              >
                Coordinator Account <i className="fa fa-user"></i>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => ActionFlag(true)}
                className="nav_item active"
                style={{ textDecoration: "none", color: "white" }}
              >
                Chat with us
              </Link>
            </li>
            <li>
              <Link to="/" className="nav_item">
                Logout
              </Link>
            </li>
          </ul>
          {open && (
            <div className="student_sidebar">
              <ul>
                <div>
                  <Link to="/coordinator" className="nav_item">
                    Home
                  </Link>
                </div>
                <div>
                  <Link
                    to="/coodinatorAccount"
                    className="nav_item active"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Coordinator Account <i className="fa fa-user"></i>
                  </Link>
                </div>
                <div>
                  <Link
                    onClick={() => ActionFlag(true)}
                    className="nav_item active"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Chat with us
                  </Link>
                </div>
                <div>
                  <Link to="/" className="nav_item">
                    Logout
                  </Link>
                </div>
              </ul>
            </div>
          )}
          <div className="menuIcon" onClick={handleOpenMenu}>
            <MenuIcon />
          </div>
        </nav>
      </header>
    </div>
  );
}
