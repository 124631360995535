import React from 'react'
import CoordinatorHome from './CoordinatorHome'
import CoordinatorAccount from './CoordinatorAccount'
import CoordinatorCreateProgram from './CreateProgramme'

import CoordinatorManageReport from './ReviewReports'
import CoordinatorTalkToUser from './TalkToUsers'
import { Route, Routes } from 'react-router-dom'
import CoordinatorChangePassword from './ChangePassword'

export default function CoordinatorRouting() {
  return (
    <div>
          <Routes>
     
            <Route path = '/coordinator' element = {<CoordinatorHome/>}/>
            <Route path = '/coodinatorAccount' element = {<CoordinatorAccount/>}/>
            <Route path = '/coordinatorCreatePrograme' element = {<CoordinatorCreateProgram/>}/>
            <Route path = '/coordinatorManageReport' element = {<CoordinatorManageReport/>}/>
            <Route path = '/coordinatorTalkToUser' element = {<CoordinatorTalkToUser/>}/>
            <Route path = '/coordinatorchangePassword' element={<CoordinatorChangePassword/>}/>

        </Routes>
    </div>
  )
}
