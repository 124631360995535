import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainNav from "./MainNav";

function ForgotPassword() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <MainNav setOpen={setOpen} />
      {open && (
        <div className="sidebar">
          <div>
            <Link
              to="/"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>
          </div>
          <div>
            <a
              href="https://pxi3111.uta.cloud/"
              target="_blank"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Blog
            </a>
          </div>
          <div>
            <Link to="/student" className="nav_item">
              Student
            </Link>
          </div>
          <div>
            <Link to="/instructor" className="nav_item">
              Instructor
            </Link>
          </div>
          <div>
            <Link to="/coordinator" className="nav_item">
              Coordinator
            </Link>
          </div>
          <div>
            <Link to="/QA" className="nav_item">
              QA Officer
            </Link>
          </div>

          <div>
            <Link to="/admin" className="nav_item">
              Admin
            </Link>
          </div>
          <div>
            <Link to="/login" className="nav_item">
              Login/register
            </Link>
          </div>
          <div>
            <Link to="/about" className="nav_item">
              About program
            </Link>
          </div>
          <div>
            <Link to="/services" className="nav_item">
              Services
            </Link>
          </div>
          <div>
            <Link to="/contactUs" className="nav_item">
              Contact US
            </Link>
          </div>
        </div>
      )}
      <div className="form_container">
        <p className="form_title">Forgot password</p>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="email">Email</label>
            <input type="text" id="email" placeholder="Email" />
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
