import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import store from './Redux/Store';
import ActionFlag from './Redux/Action';

export default function Chatbox() {
    const[input,setInput] = useState('');
    const[arr,setArr] = useState([]);
    
    
    const data = useSelector((storedata)=>{
        return storedata
    });
    console.log(data.flag)
  return (
    <div   style={data.flag ? {display:'block'}:{display:'none'}}>
      
      <div
        className="chatbox"
      
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="chatbox-top">
            <div className="chat-partner-name">
              <span className="status online"></span>
              <a target="_blank" href="https://www.facebook.com/mfreak">
                Help center
              </a>
            </div>
            <div className="chatbox-icons">
              <a href="javascript:void(0);">
                <i className="fa fa-close" onClick={() => ActionFlag(false)}></i>
              </a>
            </div>
          </div>

          <div
            className="chat-input-holder"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                height: "200px",
                width: "300px",
              }}
            >
              <div
                style={{
                  color: "black",
                  height: "170px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  margin:'20px',
                  overflowY: 'auto',
                  wordWrap:'break-word'

                }}
              >
                {arr.map((ele) => (
                  <div style={{background:'green',margin:'2px 0',height:'auto',wordWrap:'break-word',width:'90%',textAlign:'right',padding:'0 15px',borderRadius:'5px' }}><p>{ele}</p></div>
                ))}
              </div>
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <textarea
                className="chat-input"
                onChange={(e) => setInput(e.target.value)}
              ></textarea>
              <input
                type="submit"
                value="Send"
                className="message-send"
                onClick={() => setArr([...arr, input])}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
