import React from 'react';
import StudentNav from './StudentNav';

function StudentFeedback() {
  return (
    <div>
         <StudentNav/>
      <div className="sticky_bar">
        <p>Course: Feedback</p>
      </div>
      <div className="form_container">
        <p className="form_title">DX231212</p>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">Student ID</label>
            <input type="text" placeholder="Student ID" />
          </div>
          <div className="form_field">
            <label htmlFor="">Student Email</label>
            <input type="text" placeholder="Student email" />
          </div>
          <div className="form_field">
            <label htmlFor="">Instructor name</label>
            <input type="text" placeholder="Instructor name" />
          </div>
          <div className="form_field">
            <label htmlFor="">Course</label>
            <input type="text" placeholder="Course" />
          </div>
          <div className="form_field">
            <label htmlFor="">Feedback</label>
            <textarea placeholder="Enter your feedback here..." cols="35" rows="8"></textarea>
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default StudentFeedback;
