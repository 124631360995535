import React from "react";
import InstructorNav from "./InstructorNav";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const data1 = [
  {
    course: "Python for Data Science",
    id: "DATA 3402",
    name: "Week 1-4",
    file: "Introduction to Python and Data Analysis",
  },
  {
    course: "Python for Data Science",
    id: "DATA 3402",
    name: "Week 5-8",
    file: "Advanced Python Techniques",
  },
];

const data2 = [
  {
    name: "John Mcklain",
    id: "1075325785",
    courseId: "DATA 3402",
    file: "Exam 1",
    grade: "B",
  },
  {
    name: "Agatha Princely",
    id: "1054325785",
    courseId: "DATA 3402",
    file: "Exam 2",
    grade: "A",
  },
];

function InstructorFiles() {
  return (
    <div className="instructor-files">
      <InstructorNav />
      <div className="sticky_bar">
        <p>Instructor files</p>
      </div>
      <TableContainer component={Paper} sx={{ width: "80%", margin: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Course Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Course ID
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                File Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                File
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {" "}
            {data1.map((row, index) => (
              <TableRow
                key={row.course}
                sx={{
                  borderBottom:
                    index === data1.length - 1 ? "none" : "1px solid #ddd",
                }}
              >
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.course}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.id}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {" "}
                  <a
                    href="https://www.geeksforgeeks.org/data-science-tutorial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>{row.file}</u>
                  </a>
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  <a href="#">
                    <u>Edit</u>
                  </a>{" "}
                  <a href="#">
                    <u>Delete</u>
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="sticky_bar">
        <p>Student files</p>
      </div>
      <TableContainer component={Paper} sx={{ width: "80%", margin: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Student Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Student ID
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Course ID
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                File
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Grade
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {" "}
            {data2.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{
                  borderBottom:
                    index === data2.length - 1 ? "none" : "1px solid #ddd",
                }}
              >
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.id}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.courseId}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {" "}
                  <a
                    href="https://www.geeksforgeeks.org/data-science-tutorial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>{row.file}</u>
                  </a>
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.grade}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  <a href="#">
                    <u>Edit</u>
                  </a>{" "}
                  <a href="#">
                    <u>Delete</u>
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default InstructorFiles;
