import React from 'react';
import AdminNav from './AdminNav';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const data = [
  {
    name: "John Mcklain",
    id: "1075325785",
    emailId: "jxm785@mavs.uta.edu",
    course: "DATA 3402, DATA 4572",
    improvement: "xyz",
  },
  {
    name: "Linda Christian",
    id: "1075375785",
    emailId: "lxc785@mavs.uta.edu",
    course: "DATA 4572",
    improvement: "abc",
  },
];

function AdminManageCoordinator() {
  return (
    <div>
    
    <AdminNav/>
      <div className="sticky_bar">
        <p>Manage Coordinators</p>
      </div>
      <TableContainer component={Paper} sx={{ width: "80%", margin: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Coordinator  Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Coordinator ID
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Email ID
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Course Coordinated
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Improvements made
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {" "}
            {data.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{
                  borderBottom:
                    index === data.length - 1 ? "none" : "1px solid #ddd",
                }}
              >
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.id}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.emailId}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                <a href="../services.html" target="_blank">
              <u>{row.course}</u>
            </a>
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                {row.improvement} <br />
            <a href="#" target="_blank">
              <u>Proposal Tracking</u>
            </a>
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  <a href="#">
                    <u>Edit</u>
                  </a>{" "}
                  <a href="#">
                    <u>Delete</u>
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </div>
  );
}

export default AdminManageCoordinator;
