import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainNav from "./MainNav";

function AboutPage() {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <MainNav setOpen={setOpen} />
      {open && (
        <div className="sidebar">
          <div>
            <Link
              to="/"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>
          </div>
          <div>
            <a
              href="https://pxi3111.uta.cloud/"
              target="_blank"
              className="nav_item active"
              style={{ textDecoration: "none", color: "white" }}
            >
              Blog
            </a>
          </div>
          <div>
            <Link to="/student" className="nav_item">
              Student
            </Link>
          </div>
          <div>
            <Link to="/instructor" className="nav_item">
              Instructor
            </Link>
          </div>
          <div>
            <Link to="/coordinator" className="nav_item">
              Coordinator
            </Link>
          </div>
          <div>
            <Link to="/QA" className="nav_item">
              QA Officer
            </Link>
          </div>

          <div>
            <Link to="/admin" className="nav_item">
              Admin
            </Link>
          </div>
          <div>
            <Link to="/login" className="nav_item">
              Login/register
            </Link>
          </div>
          <div>
            <Link to="/about" className="nav_item">
              About program
            </Link>
          </div>
          <div>
            <Link to="/services" className="nav_item">
              Services
            </Link>
          </div>
          <div>
            <Link to="/contactUs" className="nav_item">
              Contact US
            </Link>
          </div>
        </div>
      )}
      <div className="about_container">
        <div className="content_side">
          <h4>Objectives</h4>
          <ul>
            <li>Excellence in Education</li>
            <li>Cutting edge research</li>
            <li>Industry relevance</li>
            <li>Holistic development</li>
          </ul>
          <p id="about_dec">
            Through this website, our instructors bring you up-to-date content
            to test your performance in the choice of your courses from the best
            curriculum that we have made in MSDS program with the help of our QA
            team and program coordinators.
          </p>
          <div className="social_medias">
            <h4>@MSDSatUTA</h4>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/login.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/?lang=en-in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/feed/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="about_content">
          <h3>Our course list:</h3>
          <ul>
            <li>
              DATA 3402 <br />
              Python for Data Science
            </li>
            <li>
              DATA 3421 <br />
              Data Mining, Management, and Curation
            </li>
            <li>
              DATA 3442 <br />
              Statistical Methods for Data Science
            </li>
            <li>
              DATA 3461 <br />
              Machine Learning
            </li>
            <li>
              DATA 4380 <br />
              Data Problems
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
