import React from 'react';
import StudentNav from './StudentNav';

function StudentDiscussionPage() {
  return (
    <div>
         <StudentNav/>
      <div className="sticky_bar">
        <p>Course: Discussion</p>
      </div><br /><br />
      <h3>Welcome to the Discussion Forum</h3>
      <p>This is a place for discussion on various topics.</p><br /><br />

      <h3>Discussion</h3>
      <ul>
        <div className="form_container">
          <p className="form_title">Week 1-4</p>
          <div className="form_group">
            <div className="form_field">
              <label htmlFor="">Discussion Title</label>
              <textarea placeholder="Type your message here..." cols="35" rows="2"></textarea>
            </div>
            <div className="form_field">
              <label htmlFor="">Discussion Content</label>
              <textarea placeholder="Type your message here..." cols="35" rows="5"></textarea>
            </div>
          </div>
          <div className="form_footer">
            <button className="form_submit">Submit</button>
          </div>
        </div>
      </ul>
     
    </div>
  );
}

export default StudentDiscussionPage;
