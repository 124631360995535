import React from 'react';
import CoordinatorNav from './CoordinatorNav';

function CoordinatorManageReport() {
  return (
    <div>
      <CoordinatorNav/>
      <div className="sticky_bar">
        <p>Manage Report</p>
      </div>

      <table id="table">
        <tr>
          <th>Student name</th>
          <th>Course</th>
          <th>Student Email</th>
          <th>Grade</th>
        </tr>
        <tr>
          <td>User 1</td>
          <td>Course 1</td>
          <td>abac@gamil.com</td>
          <td>A+</td>
        </tr>
        <tr>
          <td>User 1</td>
          <td>Course 1</td>
          <td>abac@gamil.com</td>
          <td>A+</td>
        </tr>
      </table>
    </div>
  );
}

export default CoordinatorManageReport;
