import React from 'react';
import InstructorNav from './InstructorNav';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const data = [
  {
    name: "Alex",
    id: "1075325785",
    email: "axy785@mavs.uta.edu",
    subject: "DATA 3402",
    grade: "B",
  },
  {
    name: "Jasmine",
    id: "1075375785",
    email: "jxi785@mavs.uta.edu",
    subject: "DATA 3402",
    grade: "A",
  },
];

function InstructorStudentDetails() {
  return (
    <div className="student-details">
       <InstructorNav/>

      <div className="sticky_bar">
        <p>Details of Students</p>
      </div>
      <TableContainer component={Paper} sx={{ width: "80%", margin: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Student Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              ID
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Email
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Subject
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Report
              </TableCell>
              <TableCell sx={{ backgroundColor: "#1a1866", color: "white" }}>
              Grade
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {" "}
            {data.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{
                  borderBottom:
                    index === data.length - 1 ? "none" : "1px solid #ddd",
                }}
              >
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.id}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.email}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.subject}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  <a href="#">
                    <u>click here</u>
                  </a>
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {row.grade}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    
    </div>
  );
}

export default InstructorStudentDetails;
