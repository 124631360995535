import { act } from "react-dom/test-utils"


function Reducer(state={flag: false},action){
    switch (action.type) {
        case 'FLAG':
          return { flag: action.payload}
       
        default:
          return state
      }
}
export default Reducer