import React from 'react';
import CoordinatorNav from './CoordinatorNav';
import { Link } from 'react-router-dom';

function CoordinatorHome() {
  return (
    <div>
     <CoordinatorNav/>

      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Coordinator!</span>
        </p>
      </div>
   
      <div className="qa_home_container">
        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/coordinatorCreatePrograme' className="qa_item">
                 Create Programs
            </Link>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/coordinatorTalkToUser' className="qa_item">
            Talk to Instructors
            </Link>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/coordinatorManageReport'className="qa_item">
                 Review Students reports
            </Link>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/coordinatorTalkToUser'className="qa_item">
            Talk to Admins
            </Link>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/coordinatorTalkToUser'className="qa_item">
            Talk to QA
            </Link>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
            <Link to='/coordinatorTalkToUser' className="qa_item">
            Talk to Students
            </Link>
        </div>
        
    
        
       
        
      </div>

   
    </div>
  );
}

export default CoordinatorHome;
