import React from 'react';
import StudentNav from './StudentNav';

const CourseContent = () => {
  return (
    <div>
        <StudentNav/>
        <div className="about_container" style={{textAlign:'left'}}>
    
    <div className="content_side">
      <h4>Course Content</h4>
      <p id="about_dec">
        Welcome to the MSDS UTA (Master of Science in Data Science at the University of Texas at Arlington) website, your one-stop destination for all your data science course needs. We offer a comprehensive range of courses to help you excel in the field of data science. Our dedicated team of specialized instructors ensures that you receive high-quality education, up-to-date information, and valuable insights throughout your learning journey.
      </p>
      <h4>Our Course List:</h4>
      <ul>
        <li>
          <strong>DATA 3402 - Python for Data Science</strong>
          <ul>
            <li>Master Python for Data Analysis: Develop proficiency in using Python as a powerful tool for data manipulation, analysis, and visualization.</li>
            <li>Apply Python in Real-world Scenarios: Learn how to apply Python skills to real data science projects, preparing you for practical challenges in the field.</li>
            <li>Build a Foundation for Advanced Data Science: Acquire the essential Python skills required for more advanced courses in the data science specialization.</li>
          </ul>
          <p>
            <a href="https://www.geeksforgeeks.org/data-science-tutorial/" target="_blank">Week 1-4: <u>Introduction to Python and Data Analysis</u></a>,
            <a href="https://www.geeksforgeeks.org/data-science-tutorial/" target="_blank">Week 5-8: <u>Advanced Python Techniques</u></a>,
            <a href="https://www.geeksforgeeks.org/data-science-tutorial/" target="_blank">Week 9-12: <u>Python for Data Science Projects</u>.</a>
          </p>
        </li>
        <li>
          <strong>DATA 3421 - Data Mining, Management, and Curation</strong>
          <ul>
            <li>Understand Data Mining Concepts: Gain a deep understanding of data mining techniques, algorithms, and their applications in extracting valuable insights from large datasets.</li>
            <li>Learn Data Management Best Practices: Develop skills in data collection, storage, and organization, ensuring data is readily accessible and usable.</li>
            <li>Master Data Curation: Learn how to clean, preprocess, and curate data for analysis, ensuring data quality and reliability.</li>
          </ul>
          <p>
            <a href="https://www.geeksforgeeks.org/introduction-to-data-mining/" target="_blank">Week 1-4: <u>Introduction to Data Mining</u></a>,
            <a href="https://www.geeksforgeeks.org/introduction-to-data-mining/" target="_blank">Week 5-8: <u>Data Management Strategies</u></a>,
            <a href="https://www.geeksforgeeks.org/introduction-to-data-mining/" target="_blank">Week 9-12: <u>Data Curation Techniques</u>.</a>
          </p>
        </li>
        <li>
          <strong>DATA 3442 - Statistical Methods for Data Science</strong>
          <ul>
            <li>Statistical Proficiency: Acquire a strong foundation in statistical methods and concepts essential for data analysis and decision-making.</li>
            <li>Apply Statistics in Data Science: Learn how to apply statistical techniques to analyze and interpret data, making informed decisions.</li>
            <li>Prepare for Advanced Data Analysis: Develop the statistical skills necessary for more advanced data science tasks and research.</li>
          </ul>
          <p>
            <a href="https://www.geeksforgeeks.org/7-basic-statistics-concepts-for-data-science/" target="_blank">Week 1-4: <u>Introduction to Statistics</u></a>,
            <a href="https://www.geeksforgeeks.org/7-basic-statistics-concepts-for-data-science/" target="_blank">Week 5-8: <u>Data Analysis with Statistics</u></a>,
            <a href="https://www.geeksforgeeks.org/7-basic-statistics-concepts-for-data-science/" target="_blank">Week 9-12: <u>Advanced Statistical Techniques</u>.</a>
          </p>
        </li>
        <li>
          <strong>DATA 3461 - Machine Learning</strong>
          <ul>
            <li>Machine Learning Fundamentals: Gain a deep understanding of machine learning algorithms, models, and techniques.</li>
            <li>Practical Machine Learning: Apply machine learning concepts to real-world problems, including classification, regression, and clustering tasks.</li>
            <li>Prepare for Advanced Machine Learning: Build a strong foundation in machine learning to excel in more advanced data science courses and projects.</li>
          </ul>
          <p>
            <a href="https://www.geeksforgeeks.org/machine-learning/" target="_blank">Week 1-4: <u>Machine Learning Fundamentals</u></a>,
            <a href="https://www.geeksforgeeks.org/machine-learning/" target="_blank">Week 5-8: <u>Practical Machine Learning</u></a>,
            <a href="https://www.geeksforgeeks.org/machine-learning/" target="_blank">Week 9-12: <u>Prepare for Advanced Machine Learning</u>.</a>
          </p>
        </li>
        <li>
          <strong>DATA 4380 - Data Problems</strong>
          <ul>
            <li>Problem-solving in Data Science: Develop advanced problem-solving skills in data science by tackling complex, real-world data challenges.</li>
            <li>Data Science Project Management: Learn how to plan, execute, and manage data science projects effectively.</li>
            <li>Enhanced Data Analysis: Apply a holistic approach to data analysis, integrating skills from various data science domains.</li>
          </ul>
          <p>
            <a href="https://www.geeksforgeeks.org/overview-of-data-science/" target="_blank">Week 1-4: <u>Problem-solving in Data Science</u></a>,
            <a href="https://www.geeksforgeeks.org/overview-of-data-science/" target="_blank">Week 5-8: <u>Data Science Project Management</u></a>,
            <a href="https://www.geeksforgeeks.org/overview-of-data-science/" target="_blank">Week 9-12: <u>Enhanced Data Analysis</u></a>
          </p>
        </li>
      </ul>
      <p>At MSDS UTA, we pride ourselves on providing not only a comprehensive curriculum but also a supportive learning environment. Our team of dedicated instructors, quality assurance specialists, and program coordinators work together to ensure that you receive the best education in data science. We're committed to delivering up-to-date information and helping you achieve your academic and career goals in this dynamic field. Explore our courses, start your journey with us, and unlock the potential of data science.</p>
      <div className="social_medias">
        <h4>@MSDSatUTA</h4>
        <ul>
          <li> <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
          <li> <a href="https://www.facebook.com/login.php" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
          <li><a href="https://twitter.com/?lang=en-in" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
          <li><a href="https://www.linkedin.com/feed/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
    </div>
   
  );
};

export default CourseContent;
