import React from "react";
import StudentNav from "./StudentNav";

function StudentAccountPage() {
  // Define a function to handle field editing (you can implement this logic)
  const editField = (field) => {
    // Implement logic to allow users to edit the specified field
    // For example, you can show input fields for editing and update the state accordingly
  };

  return (
    <div>
      <StudentNav />

      <form>
        <div className="form_container">
          <p className="form_title">Student Details</p>
          <div className="form_group">
            <div className="form_field">
              <label htmlFor="">Username</label>
              <span id="currentUsername">Current Username </span>
              <a href="#" onClick={() => editField("username")}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label htmlFor="">ID</label>
              <span id="currentUtaId">Current UTA ID </span>
              <a href="#" onClick={() => editField("utaId")}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label htmlFor="">Email</label>
              <span id="currentEmail">Current Email </span>
              <a href="#" onClick={() => editField("email")}>
                <u>edit</u>
              </a>
            </div>
            <div className="form_field">
              <label htmlFor="password">Password</label>
              <span id="currentPassword">Current Password </span>
              <a href="changepass.html" onClick={() => editField("password")}>
                <u>edit</u>
              </a>
              <button
                type="button"
                id="togglePassword"
                className="toggle-password"
              >
                <i aria-hidden="true" id="togglePasswordIcon"></i>
              </button>
            </div>
            <div className="form_footer">
              <button type="submit" className="form_submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default StudentAccountPage;
