import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorAccount() {
  return (
    <div className="instructor-account">
      <InstructorNav/>
      <div className="form_container">
        <h2 className="form_title">Instructor Details</h2>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">Username</label>
            <span id="currentUsername">Current Username </span>
            <a href="#" onClick="editField('username')">
              <u>edit</u>
            </a>
          </div>
          <div className="form_field">
            <label htmlFor="">ID</label>
            <span id="currentUtaId">Current UTA ID </span>
            <a href="#" onClick="editField('utaId')">
              <u>edit</u>
            </a>
          </div>
          <div className="form_field">
            <label htmlFor="">Email</label>
            <span id="currentEmail">Current Email </span>
            <a href="#" onClick="editField('email')">
              <u>edit</u>
            </a>
          </div>
          <div className="form_field">
            <label htmlFor="password">Password</label>
            <span id="currentPassword">Current Password </span>
            <a href="instructor-changepass.html" onClick="editField('password')">
              <u>edit</u>
            </a>
            <button type="button" id="togglePassword" className="toggle-password">
              <i aria-hidden="true" id="togglePasswordIcon"></i>
            </button>
          </div>
        </div>
        <div className="form_footer">
          <button type="submit" className="form_submit">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default InstructorAccount;
