import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StudentNav from './StudentNav';

function StudentHome() {
  const [flag, setFlag] = useState(false);
  return (
    <div className="student-home" style={{width:'100%'}}>
     
     <StudentNav/>
      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Student!</span>
        </p>
      </div>
<br/>
      <div className="sticky_bar">
        <p>
          Semester: Fall 2023 <i className="fa fa-star"></i>
        </p>
      </div>


     
      <div className="student_exam_lists">
        <div className="exam_container">
          <p className="exam_title">
            <br />
            DATA 3402 001 <br />
            Python for Data Science
          </p>
          <div className="exam_options">
            <Link to = '/studentContent'>Course Content</Link>
            <Link to = '/studentExam'>Exam</Link>
            <Link to = '/studentAnalytic'>Analytics</Link>
            <Link to = '/studentFeedback'>Feedback</Link>
            <Link to = '/studentdiscussion'>Discussion</Link>
          </div>
        </div>
        <div className="exam_container">
          <p className="exam_title">
            <br />
            DATA 3442 001 <br />
            Statistical Methods for DS
          </p>
          <div className="exam_options">
            <Link to = '/studentContent'>Course Content</Link>
            <Link to = '/studentExam'>Exam</Link>
            <Link to = '/studentAnalytic'>Analytics</Link>
            <Link to = '/studentFeedback'>Feedback</Link>
            <Link to = '/studentdiscussion'>Discussion</Link>
          </div>
        </div>
        <div className="exam_container">
          <p className="exam_title">
            <br />
            DATA 3461 002 <br />
            Machine Learning
          </p>
          <div className="exam_options">
            <Link to = '/studentContent'>Course Content</Link>
            <Link to = '/studentExam'>Exam</Link>
            <Link to = '/studentAnalytic'>Analytics</Link>
            <Link to = '/studentFeedback'>Feedback</Link>
            <Link to = '/studentdiscussion'>Discussion</Link>
          </div>
        </div>
        <div className="exam_container">
          <p className="exam_title">
            <br />
            DATA 4380 001 <br />
            Data Problems
          </p>
          <div className="exam_options">
            <Link to = '/studentContent'>Course Content</Link>
            <Link to = '/studentExam'>Exam</Link>
            <Link to = '/studentAnalytic'>Analytics</Link>
            <Link to = '/studentFeedback'>Feedback</Link>
            <Link to = '/studentdiscussion'>Discussion</Link>
          </div>
        </div>
      </div>
    

    </div>
  );
}

export default StudentHome;
