import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorChangePassword() {
  return (
    <div className="instructor-change-password">
        <InstructorNav/>
      <div className="form_container">
        <h2 className="form_title">Change Password</h2>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">Email</label>
            <input type="text" placeholder="email" />
          </div>
          <div className="form_field">
            <label htmlFor="">Current Password</label>
            <input type="password" placeholder="current password" />
          </div>
          <div className="form_field">
            <label htmlFor="">New Password</label>
            <input type="password" placeholder="new password" />
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default InstructorChangePassword;
