import React from 'react';
import QANavbar from './QANavbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const data = [
  {name: 'John Doe',email:'john3111@mavs.uta.edu',chat: '23-12-2023',number:'+16824562561' },
  { name: 'Lei greesham',email:'lei7643@mavs.uta.edu' ,chat: '23-12-2023', number:'+16824562561' },
];


function QATalkToUsers() {
  return (
    <div>
     
     <QANavbar/>
      <div className="sticky_bar">
        <p>Talk to users</p>
      </div>
      <TableContainer component={Paper} sx={{width:'80%', margin:'auto'}}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell sx={{backgroundColor:'#1a1866', color:'white'}}>Name</TableCell>
            <TableCell sx={{backgroundColor:'#1a1866', color:'white'}}>Email</TableCell>
            <TableCell sx={{backgroundColor:'#1a1866', color:'white'}}>Started chat</TableCell>
            <TableCell sx={{backgroundColor:'#1a1866', color:'white'}}>Phone no</TableCell>
          </TableRow>
        </TableHead>
        <TableBody> {data.map((row,index) => (
          <TableRow key={row.name} sx={{borderBottom:index === data.length-1 ? 'none': '1px solid #ddd'}}>
               <TableCell sx={{borderRight: '1px solid #ddd'}}>{row.name}</TableCell>
               <TableCell sx={{borderRight: '1px solid #ddd'}}><a href="Email"><u>{row.email}</u></a></TableCell>
               <TableCell sx={{borderRight: '1px solid #ddd'}}>{row.chat}<a href=""><u>Chat</u></a></TableCell>
               <TableCell sx={{borderRight: '1px solid #ddd'}}><a href="Phone"><u>{row.number}</u></a></TableCell>

          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </div>
  );
}

export default QATalkToUsers;
