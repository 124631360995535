import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorCreateExam() {
  return (
    <div className="instructor-create-exam">
        <InstructorNav/>
      <div className="form_container">
        <h2 className="form_title">Create an Exam</h2>
        <div className="form_group">
          <div className="form_field">
            <label htmlFor="">Name</label>
            <input type="text" />
          </div>
          <div className="form_field">
            <label htmlFor="">Course</label>
            <input type="text" />
          </div>
          <div className="form_field">
            <label htmlFor="">Description</label>
            <textarea rows="5" cols="30"></textarea>
          </div>
        </div>
        <div className="form_footer">
          <button className="form_submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default InstructorCreateExam;
