import React from 'react';
import AdminNav from './AdminNav';

function AdminGrades() {
  return (
    <div>
             <AdminNav/>
      <div className="sticky_bar">
        <p>Total Grades</p>
      </div>

      <table id="table">
        <tr>
          <th>Course</th>
          <th>Date of started</th>
          <th>Date of Ended</th>
          <th>Grade</th>
        </tr>
        <tr>
          <td>Course 1</td>
          <td>23-1-2023</td>
          <td>02-7-2023</td>
          <td>A+</td>
        </tr>
        <tr>
          <td>Course 2</td>
          <td>23-1-2023</td>
          <td>02-7-2023</td>
          <td>A+</td>
        </tr>
        <tr>
          <td>Course 3</td>
          <td>23-1-2023</td>
          <td>02-7-2023</td>
          <td>B</td>
        </tr>
        <tr>
          <td>Course 4</td>
          <td>23-1-2023</td>
          <td>02-7-2023</td>
          <td>C</td>
        </tr>
      </table>
    </div>
  );
}

export default AdminGrades;

